const eventsData = [
    {
        category: 'events',
        id: '1',
        alt: '',
        title: '',
        blurhash: 'L770_Y?d5P4mF_oz#-RiH;ISXm?b',
        orientation: 'landscape'
    },
    {
        category: 'events',
        id: '2',
        alt: '',
        title: '',
        blurhash: 'L36@1:}a0c1b1IF1-A,r0JEf}[-B',
        orientation: 'landscape'
    },
    {
        category: 'events',
        id: '3',
        alt: '',
        title: '',
        blurhash: 'L226$^rlD4J[WaV@tRtSHqM_yY%1',
        orientation: 'landscape'
    },
    {
        category: 'events',
        id: '4',
        alt: '',
        title: '',
        blurhash: 'L9AIGP$%1I9^}rf+NajG1Ja#,?xD',
        orientation: 'landscape'
    },
    {
        category: 'events',
        id: '5',
        alt: '',
        title: '',
        blurhash: 'L24LzfD4IvRi_4RNS*WCMcWC-;R*',
        orientation: 'landscape'
    },
    {
        category: 'events',
        id: '6',
        alt: '',
        title: '',
        blurhash: 'LB2F]1o,RdV-o+oiavWUV-j:o+f;',
        orientation: 'landscape'
    },
    {
        category: 'events',
        id: '7',
        alt: '',
        title: '',
        blurhash: 'L89P$cS#IpE2}@aejFNb0er?%1xu',
        orientation: 'landscape'
    }
];

export default eventsData;
