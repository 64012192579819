const othersData = [
    {
        category: 'others',
        id: '1',
        alt: '',
        title: '',
        blurhash: 'LOA0E%xZ9vNJ}?xYENNK=_s.I;WX',
        orientation: 'landscape'
    }
];

export default othersData;
